import {trimPath} from "../../helpers/functions";
import React from "react";

class RouteAgent {
    static map = [];

    static options = {
        path: "",
        name: "",
        middleware: [],
        prefix: "",
        page: "",
    };

    static break() {
        RouteAgent.options.middleware = [];
        return RouteAgent;
    }

    static route({path, page, name}) {
        RouteAgent.path(path).page(page).name(name).push();
    }

    static group({prefix, middleware}, callback) {
        if (prefix) {
            RouteAgent.options.prefix = `${RouteAgent.options.prefix}/${trimPath(prefix)}`;
        }

        let middlewares;

        if (middleware) {
            middlewares = typeof middleware == "object" ? middleware : {0: middleware};
            Object.entries(middlewares).forEach((val) => {
                RouteAgent.options.middleware.push(val[1]);
            });
        }

        if (middleware) {
            RouteAgent.options.middleware = RouteAgent.options.middleware.filter((mid, k) => {
                return !(mid in middlewares);
            });
        }

        callback();

        RouteAgent.options.prefix = RouteAgent.options.prefix.replace(`/${prefix}`, "");

        return RouteAgent;
    }

    static path(routePath) {
        let path = trimPath(routePath);
        if (RouteAgent.options.prefix) {
            RouteAgent.options.path = `${RouteAgent.options.prefix}/${path}`;
        } else {
            RouteAgent.options.path = path.slice(0, 1) === "/" ? "/" : "/" + path;
        }
        return RouteAgent;
    }

    static page(routeComponent) {
        RouteAgent.options.page = routeComponent;
        return RouteAgent;
    }

    static middleware(middleware) {
        RouteAgent.options.middleware.push(middleware);
        return RouteAgent;
    }

    static name(routeName) {
        RouteAgent.options.name = routeName;
        return RouteAgent;
    }

    static push() {
        RouteAgent.map.push({
            middleware: RouteAgent.options.middleware,
            path: RouteAgent.options.path,
            name: RouteAgent.options.name,
            page: RouteAgent.options.page,
        });
    }

    static getRoutes() {
        return RouteAgent.map;
    }
}

const findRouteByName = (routesArr, name) => {
    const res = routesArr.filter((v) => v.name === name);
    if (!res.length) {
        throw new Error(`Route ${name} is undefined`);
    }
    return res[0];
};

function route(name, params = {}) {
    let route = findRouteByName(RouteAgent.getRoutes(), name);

    if (route.path.match(/\{([^:]+)\}/g) && !Object.keys(params).length) {
        throw new Error(`Undefined params for route "${route.name}"`);
    }

    if (!params) return route.path;

    let path = route.path;
    Object.entries(params).forEach((param) => {
        path = path.replace(`:${param[0]}`, param[1]);
    });

    return path;
}

const composeMidd = (middArr) => (comp) => {
    return middArr.reduceRight((prev, f) => f(prev), comp);
};

function setRoutes() {
    return RouteAgent.getRoutes().map((props, i) => {
        if (props.middleware.length) {
            const RouteItem = composeMidd(props.middleware.reverse())(props.page);
            return <RouteItem key={i} path={props.path} />;
        }
        return <props.page key={i} path={props.path} />;
    });
}

export {RouteAgent, findRouteByName, route, setRoutes};
