import React from "react";
import Box from "@mui/material/Box";
import {createStyles, makeStyles} from "@mui/styles";
import Icon from "../icon";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: "flex",
            padding: "12px 16px",
            alignItems: "center",
            justifyContent: ({pos}) => (pos ? `${pos}` : `center`),
            backgroundColor: ({color, active, hoverBtn, disable, disableColor}) => disable ? disableColor : (color || active ? `${active ? hoverBtn : color}` : `#7A69EE`),
            borderRadius: "8px",
            transitionDuration: '0.2s',
            border: ({borderColor}) => borderColor ? `1px solid ${borderColor}` : null,

            '&:hover': {
                backgroundColor: ({hoverBtn, color, disable, disableColor}) => disable ? disableColor :(
                    hoverBtn ? `${hoverBtn}` : (color ? `${color}` : `rgba(122, 105, 238, 0.8)`)
                ),
                transitionDuration: '0.2s',

                '& p': {
                    color: ({hoverText, borderColor}) => (hoverText ? `${hoverText}` : (borderColor ? borderColor : "#fff")),
                    transitionDuration: '0.3s',
                }
            }
        },

        rootAdd: {
            height: "40px",
            display: "flex",
            padding: "12px 16px",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
            borderRadius: "8px",
            transitionDuration: '0.2s',
            border: "2px solid #E6E1FE",

            "& p":{
                fontSize: "16px",
                fontWeight: "500",
                marginBottom: 0,
                color: "#7A69EE",
            },

            '&:hover': {
                borderColor: "#6654e5",

                '& p': {
                    color: "#6654e5",
                    borderColor: "#6654e5",
                    transitionDuration: '0.3s',
                }
            }
        },

        removeText: {
            color: "#828DA9 !important"
        },

        plus: {
            width: "16px",
            height: "16px",
            marginRight: "8px"
        },

        title: {
            marginBottom: 0,
            fontSize: ({fontSize}) => fontSize,
            fontWeight: ({fontWeight}) => fontWeight,
            color: ({active, hoverText, borderColor}) => (active ? `${hoverText}` : (borderColor ? borderColor : `#fff`)),
        },

        icon: {
            color: ({active, hoverText}) => (active ? `${hoverText}` : `#fff`),
            marginLeft: "8px",
            width: ({iconSize}) => (iconSize ? iconSize : "24px")
        },

        iconLeft: {
            color: ({active, hoverText}) => (active ? `${hoverText}` : `#fff`),
            marginRight: "8px",
            width: ({iconSize}) => (iconSize ? iconSize : "24px")
        }

    })
);

const AppButton = ({
       color,
       onClick,
       title = "",
       iconSize = "24px",
       icon = null,
       leftIcon = null,
       pos = null,
       hoverText = null,
       hoverBtn = null,
       active = false,
       fontSize = "18px",
       fontWeight = "600",
       disable = false,
       disableColor = '#9BA4BA',
       borderColor = null,
       iconType = 'svg',
       className = null
}) => {
    const classList = useStyles({color, pos, hoverText, hoverBtn, active, iconSize, fontSize, fontWeight, disable, disableColor, borderColor});

    return (
        <Box onClick={disable ? null : onClick} className={`${classList.root} ${className}`} style={{cursor: "pointer"}}>
            {leftIcon != null ? <Icon className={`${classList.iconLeft}`}  name={`${leftIcon}`}/> : null}
            <p className={`${classList.title}`}>
                {title}
            </p>
            {icon != null ? <Icon className={`${classList.icon}`} type={iconType}  name={`${icon}`}/> : null}
        </Box>
    )
}

const AppButtonAdd = ({
          color,
          onClick,
          title = "",
      }) =>{
    const classList = useStyles({color});

    return (
        <Box onClick={onClick} className={`${classList.rootAdd}`} style={{cursor: "pointer"}}>
            <Icon name={`plus`} className={`${classList.plus}`}/>
            <p>{title}</p>
        </Box>
    )
}

const AppButtonRemove = ({
                          color,
                          onClick,
                          title = "",
                      }) =>{
    const classList = useStyles({color});

    return (
        <Box onClick={onClick} className={`${classList.rootAdd}`} style={{cursor: "pointer"}}>
            <Icon name={`minus`} className={`${classList.plus}`}/>
            <p className={`${classList.removeText}`}>{title}</p>
        </Box>
    )
}

export {
    AppButton,
    AppButtonAdd,
    AppButtonRemove
};
