import React from "react";
import {useSelector} from "react-redux";

const guest = (Wrapped) => {
    return (props) => {
        const {isAuth} = useSelector((state) => state.app.auth);
        if (isAuth) return null;

        return <Wrapped {...props} />;
    };
};

export default guest;
