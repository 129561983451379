import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useFetchAuthUser, useLogout, useLogoutOnTokenExpire} from "../../helpers/hooks";
import {notFound} from "../../helpers/functions";

const auth = (Wrapped) => {
    return (props) => {
        const isAuth = useSelector((state) => state.app.auth.isAuth);
        const fetchAuthUser = useFetchAuthUser();
        const doLogout = useLogout();

        useLogoutOnTokenExpire();

        useEffect(() => {
            if (isAuth === true) {
                return;
            } else if (isAuth === false) {
                doLogout();
            } else {
                fetchAuthUser();
            }
        }, [isAuth]);

        if (isAuth !== true) {
            return null;
        }

        return <Wrapped {...props} />;
    };
};

export const admin = (Wrapped) => {
    return (props) => {
        const {user, isAuth} = useSelector((state) => state.app.auth);
        const fetchAuthUser = useFetchAuthUser();
        useLogoutOnTokenExpire();

        useEffect(() => {
            if (isAuth !== true) {
                fetchAuthUser();
            }
            if(user?.role_id !== undefined){
                if (user?.role_id === 2) {
                    return;
                }else if (user?.role_id !== 2){
                    notFound();
                }
            }else{
                return;
            }
        }, [user, isAuth]);

        return <Wrapped {...props} />;
    };
};

export default auth;
