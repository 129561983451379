import updateTeams from "./teams";


const initialState = {
    teams: {
        list: [],
        team: {}
    },
}


const admin = (state = initialState, action) => {
    return {
        teams: updateTeams(state.teams, action),
    };
};

export default admin;
