
const initialState = {
    orders: [],
    cell: []
}

const updatePayments = (state, {type, payload}) => {
    switch (type) {
        case "FETCH_USER_CELL":
            return {
                ...state,
                cell: payload,
            };

        case "FETCH_ORDERS":
            return {
                ...state,
                orders: payload,
            };

        default:
            return state;
    }
};

const payment = (state = initialState, action) => {
    const clientState = updatePayments(state, action);
    return {
        ...clientState,
    };
};

export default payment;
