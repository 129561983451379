import {createTheme} from "@mui/material/styles";

const theme = createTheme({
    spacing: (n) => `${n * 10}px`,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 769,
            lg: 1280,
            xl: 1800,
        },
    },
    typography: {
        "fontFamily": "'Inter', sans-serif",
        "fontSize": 13,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    },
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&:focus': {
                        outline: 'none'
                    },
                },
            }
        }
    },
    props: {
        MuiWithWidth: {
            initialWidth: "lg",
        },
    },
});

export default theme;
