const initialState = {
    list: [],
}

const updateClients = (state, {type, payload}) => {
    switch (type) {
        case "FETCH_USERS_LIST":
            return {
                ...state,
                list: payload,
            };


        default:
            return state;
    }
};

const clients = (state = initialState, action) => {
    const clientState = updateClients(state, action);
    return {
        ...clientState,
    };
};

export default clients;
