import {combineReducers} from "redux";
import {getEnv} from "../helpers/functions";
import {bindActionCreators, configureStore} from "@reduxjs/toolkit";
import {apiService} from "./api-service";
import {createLogger} from "redux-logger/src";
import * as reducers from "../redux/reducers";
import {useDispatch} from "react-redux";
import actions from "./rtk-actions";

const logger = createLogger({
    collapsed: true
})

const store = configureStore({
    reducer: combineReducers({
        ...reducers,
        [apiService.reducerPath]: apiService.reducer
    }),
    middleware: getDefaultMiddleware => {
        const conf = [apiService.middleware]
        if (getEnv("REACT_APP_ENV") !== 'production') {
            conf.push(logger)
        }
        return getDefaultMiddleware().concat(conf)
    }
})

export const useActions = () => {
    const dispatch = useDispatch()
    return bindActionCreators(actions, dispatch)
}

export default store;
