const updateSimPro = (state, {type, payload}) => {
    switch (type) {
        case "FETCH_LIST_COMPANIES":
            return {
                ...state,
                companies: payload,
            };

        default:
            return state;
    }
};

export default updateSimPro;
