import React from "react";
import {Box} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        icon: {
            "&:hover": {
                background: ({hoverColor}) => `${hoverColor} !important`
            },
        }
    })
);

const Icon = ({name, dim, pointer = false, type = 'svg', background = "#bcbeca", hoverColor = undefined, ...props}) => {
    const styles = {};
    const classList = useStyles({hoverColor});
    
    if (dim !== undefined) {
        const splitDim = dim.split(",");
        styles["width"] = splitDim[0];
        styles["height"] = splitDim[1];
    }

    if (pointer) {
        styles['cursor'] = 'pointer'
    }

    if (hoverColor) {
        styles['display'] = 'inline-block'
        styles['background'] = background
        styles['WebkitMask'] = `url(/images/${name}.${type}) no-repeat center / contain`;

        return <Box component="i" style={styles} {...props} className={classList.icon} />
    }

    return <Box component="img" src={`/images/${name}.${type}`} style={styles} {...props} />;
};

export default Icon;
