export const fetchAuthUser = (payload) => {
    return {
        type: "FETCH_USER",
        payload,
    };
};

export const logoutAction = () => {
    return {
        type: "LOGOUT",
    };
};

export const loginAction = (payload) => {
    return {
        type: "LOGIN",
        payload,
    };
};
