import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {useSelector} from "react-redux";
import BlockLoaderV2 from "../loadings/block-loader-v2";
import {SIDEBAR_OPEN_WIDTH_OPTION, SIDEBAR_CLOSE_WIDTH_OPTION} from "../../config/options";
import {createStyles, makeStyles} from "@mui/styles";
import classNames from "classnames";
import {useLogout, useResize} from "../../helpers/hooks";
import Menu from "./menu";
import Logo from "../logo/logo";
import Icon from "../icon";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            height: '100vh',
            display: "grid",
            transition: 'all .5s',
            gridTemplateColumns: ({sidebar_dynamic_width}) => 
                `${sidebar_dynamic_width ? 'auto' : `${SIDEBAR_OPEN_WIDTH_OPTION}px`} 1fr`,
            backgroundColor: "#F6F8FC"
        },

        closed: {
            gridTemplateColumns: `${SIDEBAR_CLOSE_WIDTH_OPTION} 1fr !important`
        },

        container: {
            position: "relative",
            display: 'flex',
            flexFlow: 'column',
            overflowY: "scroll",
        },

        shape: {
            position: "absolute",
            bottom: "22%",
            left: "10%",
            zIndex: 1
        },

        wrapper: ({wrapperStyles}) => ({
            overflowY: "scroll",
            backgroundColor: "#fff",
            flex: 'auto',
            borderRadius: "4px",
            backgroundImage: "-webkit-linear-gradient(40deg, #1786d8 0, #00aff0 100%)",
            ...wrapperStyles
        }),

        logoutButton: {
            alignItems: "center",
            position: "absolute",
            bottom: 48,
            right: 0,
            left: 0,
            width: "100%",
            padding: "0 24px 0 24px",

            "& p":{
                color: "#fff",
                fontSize: "16px",
                fontWeight: 600
            },

            "& .button-body":{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "12px",
                borderRadius: "8px",
                width: "100%",
                backgroundColor: "#828DA9",
                color: "#FFF",

                "& div":{
                    marginRight: "8px",
                    fontWeight: 600,
                    fontSize: "18px"
                }
            }
        }
    })
);

const Layout = ({children}) => {
    const classList = useStyles();
    const {heightScreen} = useResize();
    const isAuth = useSelector((state) => state.app.auth.isAuth);

    const classes = classNames(classList.root, {
        [classList.closed]: false
    });

    return (
        <Box className={classes}>
            {isAuth ? <Box className={`${classList.container} invisible_scroll`} height={heightScreen}>
                <Box className={`${classList.wrapper} invisible_scroll`}>
                    <Icon name="shape_02" type="png" className={`${classList.shape}`} />
                    <Logo/>
                    <Process/>
                    <Menu/>
                    <LogOut/>
                </Box>
            </Box> : null}
            {children}
        </Box>
    );
};

const Process = () => {
    const process = useSelector((state) => state.app.process);
    if (process) {
        return <BlockLoaderV2 fullPageOverlay />;
    }
    return null;
};

const LogOut = () => {
    const logOut = useLogout();
    const {user} = useSelector((state) => state.app.auth);
    const classList = useStyles();

    return(
        <Box className={classList.logoutButton}>
            <p>{user.name}</p>
            <Box className={'button-body'} style={{cursor: "pointer"}} onClick={() => logOut()}>
                <Box>Logout</Box>
                <Icon name={'logout'}/>
            </Box>
        </Box>
    )
}

export default Layout;
