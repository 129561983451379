import {objVal} from "../../../helpers/functions";

const initialState = {
    calendars: [
        // {
        //     id: "ba4b2171f5160205a8ba3a22625476c59c9",
        //     name: "Test",
        //     desc: "asdasd",
        //     exist: false,
        // },
        // {
        //     id: "ba4b2171f5160205a8ba3a22625476c59c9",
        //     name: "Test",
        //     desc: "asdasd",
        //     exist: true,
        //
        // }
    ],
    brands: [],
    addListProduct: []
}

const updateCatalog = (state, {type, ...payload}) => {
    switch (type) {
        case "FETCH_CALENDARS":
            return {
                ...state,
                calendars: objVal(payload).slice(),
            };

        default:
            return state;
    }
};

const catalog = (state = initialState, action) => {
    const catalogState = updateCatalog(state, action);
    return {
        ...catalogState,
    };
};

export default catalog;
