export const fetchGlobalData = (payload) => {
    return {
        type: "FETCH_GLOBAL_DATA",
        payload,
    };
};

export const changePopUp = (payload) => {
    return {
        type: "CHANGE_POPUP",
        payload,
    };
};


export const setProcessAction = (payload) => {
    return {
        type: "SET_PROCESS",
        payload,
    };
};

export const setSidebarDynamicWidth = (payload) => {
    return {
        type: "SET_SIDEBAR_DYNAMIC_WIDTH",
        payload
    };
};

export const setCountdownAction = (payload) => {
    return {
        type: "FETCH_COUNTDOWN",
        payload,
    };
};

export const setOpenSidebarAction = (payload) => {
    return {
        type: "SET_OPEN_SIDEBAR",
        payload,
    };
};

export const setOpenFilterSlidebarAction = (payload) => {
    return {
        type: "SET_OPEN_FILTER_SLIDEBAR",
        payload,
    };
};

export const setOpenFilterSlidebarMadelAction = (payload) => {
    return {
        type: "SET_OPEN_FILTER_SLIDEBAR_MODEL",
        payload,
    };
};

export const setCrumbsAction = (payload) => {
    return {
        type: "SET_CRUMBS",
        payload,
    };
};


export const styleWrapperAction = (payload) => {
    return {
        type: "STYLE_WRAPPER",
        payload
    }
}

export const setLastUpdateCatalog = (payload) => {
    return {
        type: "SET_LAST_UPDATE_CATALOG",
        payload
    }
}
