import updateSimPro from "./sim_pro";
import updateCards from "./cards";


const initialState = {
    simPro: {
        companies: []
    },
    cards: {
        list: []
    },
}


const settings = (state = initialState, action) => {
    return {
        simPro: updateSimPro(state.simPro, action),
        cards: updateCards(state.cards, action),
    };
};

export default settings;
