import React from "react";
import {Box} from "@mui/material";
import classNames from "classnames";
import useStyles from "./style";
import Icon from "../icon";

const BlockLoaderV2 = ({height, dim, fullPageOverlay, overlay}) => {
    const classList = useStyles({overlay});

    const className = classNames(classList.root, {
        [classList.fullPageOverlay]: fullPageOverlay,
    });

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={className}
            height={`${!fullPageOverlay ? `${parseInt(height)}px` : "100%"}`}>
            <Box className={classList.inner}>
                <Icon name="preloads" width="41px" />
            </Box>
        </Box>
    );
};

BlockLoaderV2.defaultProps = {
    height: 100,
    dim: 35,
    fullPageOverlay: false,
};

export default BlockLoaderV2;
