const initialState = {
    menu: [
        {
            name: "Google calendars",
            path: "/dashboard/calendar/list/",
            isDrop: false
        },
        {
            name: "Simpro set up",
            path: "/dashboard/settings/sim-pro",
            isDrop: false,
        },
        {
            name: "Invoices",
            path: "/dashboard/payment/",
            isDrop: false,
        },
        {
            name: "Users",
            path: "/dashboard/users/",
            isDrop: false,
        },
        {
            name: "Cards",
            path: "/dashboard/settings/cards",
            isDrop: false,
        }
    ],
    admin_menu: [
        {
            name: "Admin",
            path: "/dashboard/admin/teams",
            isDrop: false
        },
    ],
    open_pop_up: false,
    toggle_main_menu: true,
    redirect_route: {
        route_name: 'home',
        params: []
    },
    auth: {
        user: {},
        isAuth: null,
    },

    process: false,
}

const updateMainStates = (state = initialState, {type, payload}) => {
    switch (type) {
        case "FETCH_GLOBAL_DATA":
            return {
                ...state,
                ...payload,
            };

        case "CHANGE_POPUP":
            return {
                ...state,
                open_pop_up: payload,
            };

        default:
            return state;
    }
};

const updateAuthStates = (state = initialState, {type, payload}) => {
    switch (type) {
        case "FETCH_USER":
            const status = Object.keys(payload).length;
            return {
                isAuth: !!status,
                user: payload,
            };

        case "LOGOUT":
            return {
                isAuth: false,
                user: {},
            };

        case "LOGIN":
            return {
                isAuth: true,
                user: payload,
            };

        default:
            return state.auth;
    }
};

const app = (state = initialState, action) => {
    const mainStates = updateMainStates(state, action);
    const authStates = updateAuthStates(state, action);

    return {
        ...mainStates,
        auth: authStates,
    };
};

export default app;
