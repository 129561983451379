import {objVal} from "../../../helpers/functions";

const updateTeams = (state, {type, payload}) => {
    switch (type) {
        case "FETCH_LIST_TEAMS":
            return {
                ...state,
                list: objVal(payload),
            };

        case "FETCH_TEAM_ITEM":
            return {
                ...state,
                team: payload,
            };

        default:
            return state;
    }
};

export default updateTeams;
