import {createApi} from "@reduxjs/toolkit/dist/query/react";
import axios from "axios";

const axiosBaseQuery = () => async ({ url, method, data, params }) => {
    try {
        const result = await axios({url: url, method, data, params})
        return {data: result?.data}
    } catch (error) {
        const httpStatus = error.response.status
        const props = error.response.data

        if (httpStatus === 400) {
            if (props.message) {
                //handleEnqueueSnackbar(snackActions.notify, props.message, props.status)
            }
        }
        return {error: {data: props}}
    }
}

export const apiService = createApi({
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 60, // cache life (sec)
    refetchOnReconnect: true,
    tagTypes: [],
    endpoints: () => ({}),
})
