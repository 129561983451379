import {format} from "date-fns";
import React from "react";
import moment from "moment";
import "moment-timezone";

export const prepareRequestUrl = (url) => {
    return `/${trimPath(url)}`;
};

export const uploadPath = (path) => {
    return getEnv("REACT_APP_API_HOSTNAME") + prepareRequestUrl("uploads/" + path);
};

export const getEnv = (name) => {
    return process.env[name];
};

export const isLocal = () => {
    return getEnv("REACT_APP_ENV") === "local";
};

export const empty = (data) => {
    if (data === null) {
        data = {};
    }

    if (typeof data != "object" && typeof data !== "array") {
        data = {};
    }

    let length;
    if (typeof data == "object") {
        length = Object.keys(data).length > 0;
        return !length;
    } else if (typeof data == "array") {
        length = data.keys().length > 0;
        return !length;
    } else if (!data) {
        return true;
    }

    return false;
};

export const countArr = (array) => {
    if ((typeof array != "object" && typeof array !== "array") || array === null) {
        array = {};
    }

    let length = 0;
    if (typeof array == "object") {
        length = Object.keys(array).length;
    } else if (typeof array === "array") {
        length = array.keys().length;
    }

    return length;
};

export const isArray = (value) => typeof value === "array"

export function trimLeft(str, charlist) {
    if (charlist === undefined) charlist = "/\\/$/";

    return str.replace(new RegExp("^[" + charlist + "]+"), "");
}

export function trimRight(str, charlist) {
    if (charlist === undefined) charlist = "/\\/$/";

    return str.replace(new RegExp("[" + charlist + "]+$"), "");
}

export function trimPath(str, charlist) {
    return trimRight(trimLeft(str, charlist), charlist);
}

export const inArray = (index, arr) => {
    if (index === null || arr === undefined) {
        return false;
    }

    return (
        Object.entries(arr).filter((v) => {
            return index === v[1];
        }).length > 0
    );
};

export const removeItemArrBySlug = (clients, slug, key = "id") => {
    const index = clients.forEach((item) => item[key]).indexOf(slug);
    return [...clients.slice(0, index), ...clients.slice(index + 1)];
};

export const ifScrolledToBottom = (pxFromBottom = 0) => {
    const innerHeight = window.innerHeight;
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.scrollingElement.scrollHeight;

    return innerHeight + scrollTop >= scrollHeight - pxFromBottom;
};

export const flexStartProps = () => {
    return {
        display: "flex",
        justifyContent: "flex-start"
    };
};

export const dateFormat = (date = null, f = "YYYY-MM-DD") => {
    const momentDate = moment(date || new Date())
    return momentDate.isValid() ? momentDate.format(f)
                                : moment(new Date(date || moment())).format(f)
};

export const priceString = (price, decimals = 2) => {
    if (!price) {
        return 0;
    }

    return new Intl.NumberFormat("ru-RU", {maximumFractionDigits: decimals}).format(price).replace(",", ".");
};

export const formatNumberInputVal = (val) => {
    return isNum(val) && val !== '' ? parseFloat(val) : val
}

export const isNull = (v) => {
    return v === null
}

export const numberFormat = (nr, decimals = 2) => {
    return new Intl.NumberFormat("ru-RU", {maximumFractionDigits: decimals}).format(nr).replace(",", ".");
};

export const pluck = (array, key) => {
    const newArr = [];
    Object.values(array).forEach((value) => {
        if (key in value) {
            newArr.push(value[key]);
        }
    });
    
    return newArr.filter((v, i, a) => a.indexOf(v) === i);
};

export const keyBy = (array, key) => {
    const newArr = [];
    Object.values(array).forEach((value) => {
        newArr[value[key]] = value;
    });

    return newArr;
};

export const groupBy = (arr, field, withSlash = true) => {
    const groups = Object.create(null);

    arr.forEach(function (o) {
        let key = (withSlash ? " " : "") + o[field];
        if (!groups[key]) {
            groups[key] = [];
        }
        groups[key].push(o);
    });
    return groups;
};

export function fileDownloader(data, filename, mime, bom) {
    var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
    var blob = new Blob(blobData, {type: mime || "application/octet-stream"});
    if (typeof window.navigator.msSaveBlob !== "undefined") {
        window.navigator.msSaveBlob(blob, filename);
    } else {
        var blobURL =
            window.URL && window.URL.createObjectURL
                ? window.URL.createObjectURL(blob)
                : window.webkitURL.createObjectURL(blob);
        var tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = blobURL;
        tempLink.setAttribute("download", filename);

        if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        // Fixes "webkit blob resource error 1"
        setTimeout(function () {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }, 200);
    }
}

export const sortBy = (arr, key) => {
    const sortedItems = objVal(arr).sort(function (a, b) {
        if (a[key] > b[key]) {
            return 1;
        }

        if (a[key] < b[key]) {
            return -1;
        }

        return 0;
    });

    return objVal(sortedItems);
};

export const sortByDesc = (arr, key) => {
    const sortedItems = objVal(arr).sort(function (a, b) {
        if (a[key] > b[key]) {
            return -1;
        }

        if (a[key] < b[key]) {
            return 1;
        }

        return 0;
    });

    return objVal(sortedItems);
};

export const objVal = (values) => {
    return Object.values(values);
};

export const getFieldsFromObj = (arr, fields = []) => {
    const obj = {};
    Object.entries(arr).map((v) => {
        if (inArray(v[0], fields)) {
            obj[v[0]] = v[1];
        }
    });
    return obj;
};

export const objKeys = (values) => {
    return Object.keys(values);
};

export const monthName = (month) => {
    const date = new Date(`${month}-01`);
    return date.toLocaleString("en", {month: "long"});
};

export const locationHref = (path) => {
    window.location.href = path;
};

export const makeRedirect = (url) => {
    locationHref(url);
};

export const redirectToLogin = () => {
    locationHref(getEnv("REACT_APP_API_HOSTNAME"));
};

export const redirectToLogout = () => {
    locationHref(getEnv("REACT_APP_API_HOSTNAME") + "/logout");
};

export const notFound = () => {
    locationHref("/dashboard/404");
};

export const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
};

export const sumByKey = (arr, key) => {
    return objVal(arr).reduce((accumulator, current) => {
        return key in current ? accumulator + Number(current[key]) : 0
    }, 0);
};

export const isFnc = (fnc) => {
    return typeof fnc === "function";
};

export const getPageUpDataFromArr = (data) => {
    const arr = [];
    data.forEach(({id, page_up, childs}) => {
        arr.push({id, page_up});
        if (!empty(childs)) {
            objVal(childs).forEach(({id, page_up}) => {
                arr.push({id, page_up});
            });
        }
    });
    return arr;
};

export const isChecked = (val) => {
    return inArray("on", val) || val === 1 || val === true;
};

export const weekDays = () => {
    return [
        {
            num: 1,
            name: 'Пн'
        }, {
            num: 2,
            name: 'Вт'
        }, {
            num: 3,
            name: 'Ср'
        }, {
            num: 4,
            name: 'Чт'
        }, {
            num: 5,
            name: 'Пт'
        }, {
            num: 6,
            name: 'Сб'
        }, {
            num: 7,
            name: 'Вс'
        }
    ]
}

export const convertToLocalTimezone = (time) => {
    const moscow = moment.tz(time, "Europe/Moscow");
    const toCurrentTimezone = moscow.clone().tz(moment.tz.guess());
    return moment(toCurrentTimezone.format());
};


export const momentTz = (date = false) => {
    return (date ? moment(date) : moment()).tz('Europe/Moscow')
}

export const confirmAlert = (msg = null) => {
    return window.confirm(msg || "Подтвердить операцию");
};


export const arrUnique = (array) => {
    return objVal(array).filter((value, index, self) => {
        return self.indexOf(value) === index;
    });
};


export const int = (n) => parseInt(n);

export const float = (n) => parseFloat(n);

export const percentFormat = (n) => {
    return Number(n.toString().match(/^\d+(?:\.\d{0,2})?/));
};

export const countRentability = (markupPrice, withoutMarkupPrice) => {
    const percent = percentFormat(
        withoutMarkupPrice && markupPrice ? ((markupPrice - withoutMarkupPrice) / withoutMarkupPrice) * 100 : 0
    );
    const price = markupPrice - withoutMarkupPrice;
    return {price, percent};
};

export const objToQueryString = (obj) => {
    let q = [];
    Object.entries(obj).forEach((v) => {
        q.push(`${v[0]}=${v[1]}`);
    });
    return q.join("&");
};

export const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;

export const getLocalStorageObj = (key) => {
    return JSON.parse(localStorage.getItem(key)) || {};
};


export const setArrayToLocalStorage = (key, arr) => {
    localStorage.setItem(key, JSON.stringify(arr));
};

export const getArrayFromLocalStorage = (key) => {
    return JSON.parse(localStorage.getItem(key));
};

export const removeFromLocalStorage = (key) => {
    localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
    objKeys(localStorage).forEach((key) => {
        removeFromLocalStorage(key);
    });
};

export const arraySum = (arr, field = null) => {
    let sum = 0;
    objVal(arr).forEach((v) => {
        sum += field ? parseFloat(v[field]) : parseFloat(v)
    });
    return sum
}

export const isNum = (val) => !isNaN(val)

export const getCellGridsFromTheads = (arr) => {
    const cellsGrid = [];
    arr.forEach(({view, width}) => {
        if (view || view === undefined) {
            cellsGrid.push(width)
        }
    })
    return cellsGrid
}

export const substring = (str, maxLength, end = '...') => {
    if (String(str || '').length > maxLength) {
        return `${String(str).substring(0, maxLength)}${end}`
    }
    return str
}

export const filterObjByKeys = (obj, keys) => {
    const newObj = {}
    keys.forEach((key) => {
        if (key in obj) {
            newObj[key] = obj[key] !== null ? obj[key] : ''
        }
    })

    return newObj
}

export const momentAdd = (value, unit, format = "DD-MM-YYYY") => {
    return moment().add(value, unit).format(format)
}

export const removeEmojis = (text) => {
    if (!text) {
        return '';
    }
    return text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
}

export const hasArrayValues = (arr) => {
    return Object.values(arr).filter((v) => Boolean(v)).length > 0
}

export const createName = (number, name, canViewNumber, canViewName) => {
    let fullName = ''
    if (canViewNumber) fullName += number
    if (canViewNumber && canViewName) fullName += ' '
    if (canViewName) fullName += name
    return fullName;
}

export const reorder = (list, startIndex, endIndex) => {
    const result = objVal(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result.map((v, k) => {
        return {...v, page_up: k + 1};
    });
};

export const hexToRgb = (hex) => {
    hex = hex.replace(/^#/, '');

    var bigint = parseInt(hex, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;

    return r + "," + g + "," + b;
}

export const deleteItemById = (idToDelete, directories) => {
    const updatedDirectories = JSON.parse(JSON.stringify(directories));
    const deleteFromDirectories = (idToDelete, directories) => {
        for (let i = 0; i < directories.length; i++) {
            const directory = directories[i];

            if (directory.id === idToDelete) {
                directories.splice(i, 1);
                return;
            }
            if (directory.child) {
                deleteFromDirectories(idToDelete, directory.child);
            }
        }
    };
    deleteFromDirectories(idToDelete, updatedDirectories);
    return updatedDirectories;
}
