import axios from "axios";
import {getEnv, redirectToLogout, prepareRequestUrl} from "../helpers/functions";
import {localStorageLogout} from "../helpers/hooks";

const apiUrl = getEnv("REACT_APP_API_URL");

axios.defaults.baseURL = apiUrl;

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const httpStatus = error?.response?.status
        if (error.response) {
            if (httpStatus === 401 && !error.response.config.url.includes("logout")) {
                localStorageLogout();
                redirectToLogout();
            }
        }

        if (httpStatus === 500) {
            alert('Server Error.. Try again')
        }

        return error
    }
);

export const fetcher = (url) =>
    fetch(apiUrl + prepareRequestUrl(url), {
        headers: getHeaders(),
        // mode: 'no-cors',
    }).then((r) => r.json());

export async function getRequest(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(prepareRequestUrl(url), {
            headers: getHeaders(),
            params: params,
            responseType: "json",
        }).then((result) => {
            if (result?.response) {
                reject(result?.response)
            } else {
                resolve(result?.data)
            }
        })
    })
}

export async function dwnRequest(url, params = {}, api = true) {
    return await axios.get(prepareRequestUrl(url), {
        headers: getHeaders(),
        params: params,
        responseType: "blob",
        baseURL: api ? apiUrl : getEnv("REACT_APP_API_HOSTNAME"),
    });
}

export async function postRequest(url, data = {}) {
    return new Promise((resolve, reject) => {
        return axios.post(prepareRequestUrl(url), data, {
            headers: getHeaders(data?.token),
        }).then((result) => {
            if (result?.response) {
                reject(result?.response)
            }
            resolve(result?.data)
        })
    })
}

export async function deleteRequest(url, id) {
    return new Promise((resolve, reject) => {
        return axios.delete(prepareRequestUrl(url) + `/${id}`).then((result) => {
            if (result?.response) {
                reject(result?.response)
            }
            resolve(result?.data)
        })
    })
}

export const getHeaders = (token = "") => {
    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    let apiToken = token || localStorage.getItem("api_token");

    if (apiToken) {
        Object.assign(headers, {
            Authorization: "Bearer " + apiToken,
        });
    }

    return headers;
};
