import React from "react";
import {Box} from "@mui/material";

const LayoutInner = ({children}) => {
    return (
        <Box mt="25px" mb="235px">
            {children}
        </Box>
    );
};

export default LayoutInner;
